import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import GradientText from "@components/common/gradient-text"
import Flex from "@components/elements/flex"
import styled, { useTheme } from "styled-components"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import Anchor from "@components/elements/anchor"
import { HighGradient, LowGradient } from "@components/common/gradient"
import { LogoCarousel } from "@components/common/logo-carousel"
import { TestimonialUser } from "@components/common/testimonial"
import { Logo } from "@components/common/logo"
import { useBadges } from "@hooks/use-badges"
import { CONTAINER_SPACING } from "@utils/standard-spacing"
import media from "../../styles/media"
import { useGradient } from "@hooks/use-gradient"
import { OurNumbers } from "@components/pages/about/our-numbers"
import { useInViewResponsive } from "@hooks/use-in-view-responsive"

const InvestorCard = styled.a`
  display: flex;
  flex-flow: column;
  grid-column: span 1;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0px 1px rgb(0 0 0 / 20%), 0 8px 16px rgb(0 0 0 / 10%);
  padding: 1rem;
  border-radius: 0.5rem;

  .klipfolio-image-wrapper {
    max-width: 120px;
  }

  p,
  span {
    color: #21264f;
  }

  p {
    font-size: 0.8rem;
  }

  span {
    font-size: 1rem;
    font-weight: 700;
  }
`

const LogoContainer = styled.div`
  svg {
    height: 50px;
    margin-bottom: -1.04rem;

    ${media.md`
      height: 42px;
    margin-bottom: -1.2rem;
    `}

    ${media.sm`
      height: 34px;
      margin-bottom: -0.88rem;
    `}
  }
`

const MicrositeAboutPage = ({ data }) => {
  const { color, shadow } = useTheme()
  const images = processImages(data.images.edges)
  const icons = processImages(data.icons.edges)
  const investors = data.investors.edges
  const badges = useBadges()
  const { gradient } = useGradient()

  const numbersRef = useRef(null)
  const numbersInView = useInViewResponsive(numbersRef, 0.2, 0.6)

  return (
    <Layout
      fullWidth
      title={`About Us`}
      description={`Klipfolio was founded back in 2001 to tackle the challenges of real-time data. Since then, we've evolved and changed quite a bit.`}
      marginless
      microsite
    >
      <Container relative fullWidth noPadding hideOverflow>
        <HighGradient
          src={images["b143ae2d-fab4-4821-b1a9-c3766fcf9317"].cdn}
          loading="eager"
        />
        <Image
          eager
          file={data.hero}
          style={{ zIndex: -1, minHeight: "130px" }}
        />
        <Div textContainer position="relative">
          <Flex
            margin="-6rem auto 6rem"
            marginMd="-3rem auto 6rem"
            marginSm="-1rem auto 4rem"
            gap="4rem"
            gapSm="2rem"
          >
            <Flex gap="1rem">
              <Heading center fontSize="4rem" mobileScale={0.6}>
                About&nbsp;
                <GradientText
                  gradient={
                    "linear-gradient(180deg, #6E79E2 -25%, #D03D84 125%)"
                  }
                >
                  PowerMetrics
                </GradientText>
              </Heading>
              <Paragraph center fontSize="1.3rem" lineHeight="180%">
                PowerMetrics is designed to enable everyone, not only data
                experts, to confidently use and analyze trusted data to make
                better decisions. In 2021, we launched PowerMetrics, a
                metric-centric approach to analytics that reflects our
                relentless drive for innovation. All{" "}
                <Anchor link="https://www.klipfolio.com/">Klipfolio</Anchor>{" "}
                products are informed by insights from thousands of customers
                and fuelled by our mission to help everyone succeed with data.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Flex
                margin="0 auto"
                flexFlow="row"
                gap="1.1rem"
                gapMd="0.9rem"
                gapSm="0.7rem"
                alignItems="center"
              >
                <Heading color="black" fontSize="3rem" as="h2" center>
                  About
                </Heading>
                <LogoContainer>
                  <Logo.Klipfolio dark />
                </LogoContainer>
              </Flex>
              <Paragraph center>
                Klipfolio is the company, and the team, behind PowerMetrics.
                Over the last 20 years, Klipfolio has helped thousands of people
                worldwide make data-driven decisions. We started with&nbsp;
                <Anchor link={"https://www.klipfolio.com/klips"}>Klips</Anchor>,
                a visualization and dashboard solution, and have grown to
                include&nbsp;
                <Anchor link={"/"}>PowerMetrics</Anchor>, a forward-thinking
                alternative to traditional BI.&nbsp;
                <Anchor link={"https://www.klipfolio.com/about"}>
                  Read more
                </Anchor>
                &nbsp;about Klipfolio’s mission and values and the PowerMetrics
                team that brings it all together.
              </Paragraph>
            </Flex>
          </Flex>
        </Div>

        <OurNumbers ref={numbersRef} inView={numbersInView} />

        <Flex textContainer gap="1rem">
          <Paragraph fontWeight={700} fontSize="1.6rem" center margin="0">
            We’re proud of our track record
          </Paragraph>
          <Image
            objectFit="scale-down"
            file={images["a7cc0343-755c-41e1-8b1f-0376120d9f44"]}
            style={{ maxWidth: "550px", margin: "0 auto" }}
          />
          <Image
            objectFit="scale-down"
            style={{ maxWidth: "400px", margin: "0 auto" }}
            file={badges}
          />
        </Flex>

        <Grid
          container
          gap="3rem"
          gapMd="1rem"
          gapSm="1rem"
          columns="1fr 1fr"
          columnsMd="1fr"
          columnsSm="1fr"
          {...CONTAINER_SPACING}
        >
          <Flex
            gap="1.5rem"
            background={color.indigo100}
            padding="2.5rem"
            paddingSm="1.5rem"
            borderRadius="1rem"
            justifyContent="center"
          >
            <Paragraph lineHeight={1.6} center>
              “With investments in big data analytics and infrastructure
              expected to exceed $100B by 2027, why do most people feel
              overwhelmed and frustrated when working with self-serve BI
              solutions? We believe it’s because investment dollars are going to
              the bottom of the data stack instead of to the people who use the
              technology to make data-driven decisions. PowerMetrics is about to
              change that.”
            </Paragraph>
            <TestimonialUser
              name={"Allan Wille"}
              title={"CEO and Co-Founder"}
              image={images["3d7d9b7b-f24a-4526-a9cf-55d849c80d6a"]}
            />
          </Flex>
          <Flex
            gap="1.5rem"
            background={color.indigo100}
            padding="2.5rem"
            paddingSm="1.5rem"
            borderRadius="1rem"
            justifyContent="center"
          >
            <Paragraph lineHeight={1.6} center>
              “PowerMetrics is a BI solution for everyone. Metrics combine the
              needs of the data team for data governance, structure and process
              with the needs of business teams for consistent, well-defined data
              and self-serve analytics.”
            </Paragraph>
            <TestimonialUser
              name={"Peter Matthews"}
              title={"CXO and Co-Founder"}
              image={images["43584351-5a43-4062-a59b-261d76a38565"]}
            />
          </Flex>
        </Grid>

        <Div hideOnMd {...CONTAINER_SPACING}>
          <Heading center as="h2" fontSize="3rem" margin="0 0 2rem">
            Learn from our
            <br />
            <GradientText gradient="linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)">
              thought leaders
            </GradientText>
          </Heading>

          <Grid center gap="4rem" textContainer columns="repeat(2, 1fr)">
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["07a99c63-10ab-4a46-b377-6fdb54bc1ef7"]}
                />
                <Anchor
                  arrow
                  link="https://www.klipfolio.com/blog"
                  new
                  fontSize="1.3rem"
                  lineHeight="140%"
                >
                  Klipfolio Blog
                </Anchor>
              </Div>
              <Paragraph Paragraph fontSize="1.1rem" lineHeight="140%">
                Read blog posts on the topics that matter to you and your
                business.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["b9c999df-d4dd-47d8-b0f1-63a0f7391b53"]}
                />
                <Anchor
                  arrow
                  fontSize="1.3rem"
                  lineHeight="140%"
                  link="https://www.metrichq.org/"
                >
                  MetricHQ
                </Anchor>
              </Div>
              <Paragraph fontSize="1.1rem" lineHeight="140%">
                Find industry-standard metric definitions and choose from
                hundreds of pre-built metrics.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["3ce41c96-ab4e-44f7-8d94-e5f2dbb95390"]}
                />
                <Anchor
                  arrow
                  fontSize="1.3rem"
                  lineHeight="140%"
                  link="https://metricstack.substack.com/"
                >
                  Metric Stack Newsletter
                </Anchor>
              </Div>
              <Paragraph fontSize="1.1rem" lineHeight="140%">
                Learn from today&apos;s thought leaders about metric-centric
                analytics in the modern data stack.
              </Paragraph>
            </Flex>
            <Flex gap="1rem">
              <Div>
                <Image
                  height={50}
                  width={50}
                  style={{
                    borderRadius: "100%",
                    margin: "0 auto 1rem",
                    boxShadow: shadow.soft,
                  }}
                  file={icons["09a6a2d6-aaca-4331-acb7-48fe7669e820"]}
                />
                <Anchor
                  arrow
                  fontSize="1.3rem"
                  lineHeight="140%"
                  link="https://www.klipfolio.com/metric-stack"
                >
                  Metric Stack Podcast
                </Anchor>
              </Div>
              <Paragraph fontSize="1.1rem" lineHeight="140%">
                Listen to founders and other business leaders as they share how
                they succeed with data.
              </Paragraph>
            </Flex>
          </Grid>
        </Div>

        <Flex
          position="relative"
          center
          background={color.indigo700}
          {...CONTAINER_SPACING}
          padding="8rem 1rem 8rem"
          paddingSm="4rem 1rem 4rem"
          gap="2rem"
          gapSm="1rem"
          overflow="hidden"
        >
          <LowGradient src={gradient.cdn} />
          <Heading color="white" center as="h2" fontSize="3rem">
            Don&apos;t just&nbsp;
            <br />
            <GradientText gradient="linear-gradient(90deg, #7B6CCC 0%, #BC6894 100%)">
              take our word for it
            </GradientText>
          </Heading>
          <Paragraph
            fontSize="1.4rem"
            color="white"
            lineHeight="150%"
            maxWidth="60ch"
            center
            margin="0 auto -4rem"
          >
            At Klipfolio, we are inspired every day by our amazing customers.
            Thank you to all the data heroes out there for sharing your success
            with us.
          </Paragraph>
          <div style={{ filter: "brightness(2)" }}>
            <LogoCarousel />
          </div>
          <Image
            style={{
              filter: "brightness(10)",
              maxWidth: "700px",
              margin: "0 auto",
              padding: "0 1rem",
            }}
            file={images["6a591b3b-ba1e-49df-aea6-fbfd87e562d1"]}
          />
        </Flex>

        <Flex {...CONTAINER_SPACING} gap="2rem" gapSm="1rem" textContainer>
          <Flex center gap="2rem" gapSm="1rem">
            <Heading as="h2" fontSize="3rem">
              Our Investors
            </Heading>
            <Grid gap="1rem" columns="repeat(auto-fill, minmax(180px, 1fr))">
              {investors.map(({ node: investor }, i) => {
                return (
                  <InvestorCard key={`investor-${i}`} href={investor.url}>
                    <Image file={investor.investor} objectFit="scale-down" />
                  </InvestorCard>
                )
              })}
            </Grid>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

MicrositeAboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MicrositeAboutPage

export const pageQuery = graphql`
  query MicrositeAboutPageQuery {
    investors: allInvestors(filter: { status: { eq: "published" } }) {
      edges {
        node {
          url
          investor {
            cdn
            placeholder
            id
            title
          }
        }
      }
    }
    hero: directusFiles(
      directus_id: { eq: "12f51f24-fdab-4131-bd5d-0e6f0815ad5c" }
    ) {
      cdn(width: 2100, quality: 100)
      directus_id
      id
      placeholder
      title
    }
    icons: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b9c999df-d4dd-47d8-b0f1-63a0f7391b53"
            "07a99c63-10ab-4a46-b377-6fdb54bc1ef7"
            "09a6a2d6-aaca-4331-acb7-48fe7669e820"
            "3ce41c96-ab4e-44f7-8d94-e5f2dbb95390"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "43584351-5a43-4062-a59b-261d76a38565"
            "3d7d9b7b-f24a-4526-a9cf-55d849c80d6a"
            "e790791c-30f3-4a60-be5a-6688e7d4a8f2"
            "b143ae2d-fab4-4821-b1a9-c3766fcf9317"
            "6a591b3b-ba1e-49df-aea6-fbfd87e562d1"
            "d81cdb7c-ad6a-4e5f-ac57-9da1ebdd14c0"
            "ea14cb64-be70-480f-92d1-6664cceb7234"
            "294fdc12-f8ab-435e-b99e-501ee68a6ccb"
            "582522df-27cf-4067-ab1c-a4970e73bddc"
            "a7cc0343-755c-41e1-8b1f-0376120d9f44"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    users: allOurUsers {
      edges {
        node {
          company {
            title
            cdn
            placeholder
            directus_id
          }
        }
      }
    }
  }
`
